
import { toPairs } from 'lodash';
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import { paisDefecto, SelectClientes } from '../../../helpers/selects';
import Api from './../../../crud/Api';




export const FuncionesSimulador = (clientes, setClientes, setGlobalCargando,idRol,esRolAdministrativo) => {


    const cargaSelects = useCallback(() => {


        SelectClientes().then(data => {
            setClientes(data);
        });



    }, [setClientes]);


    const handleSubmit = async (values, { setStatus, setFieldValue, setSubmitting }) => {

        let campos={};
        let servicios={}

            
        setSubmitting(true);

       if (values.inicializaCampos===true) {            
            setFieldValue("red","");
            setFieldValue("selectServicios",[]);
            setFieldValue("selectRedes", []);    
            setFieldValue("cargaServicios",true);
            setFieldValue("valoraciones",[]);
        }
        
    
        if (values.cliente !== "") {

            if (values.inicializaCampos === true ) {
                campos = await cargaCampos(values);
                values = { ...values, ...campos };

                let arr = toPairs(campos);
                arr.forEach((item) => {
                    setFieldValue(item[0], item[1]);

                });

                values.inicializaCampos=false;
                

            }

          
            setGlobalCargando(true);
            
         
            if (values.cliente !== "" && values.cliente !== undefined  ) {
                setStatus(null);
                setSubmitting(false);
                servicios = await cargaServicios(values);
                
                if(servicios!==false){

                    setGlobalCargando(false); 
                    
                    let arr = toPairs(servicios);       
                    
                    
                    arr.length>0 && arr.forEach((item) => {
                        setFieldValue(item[0], item[1]);

                    });

                    
                    if(servicios.idRuta>0)
                        setFieldValue("idRuta",servicios.idRuta);

              }else{
                    setGlobalCargando(false); 
                    setFieldValue("idRuta", "");
                    setFieldValue("objDatos", { redes: [] } );
                    setFieldValue("servicios", []);                    
              }

                setFieldValue("cargaServicios", false);
                

            } else {
                setSubmitting(false);
                setGlobalCargando(false);
            }

            if(servicios!==false)
             setFieldValue("inicializaCampos", false);

        } else {
           
            
                setStatus("Debes seleccionar cliente");
                setSubmitting(true);    
                setGlobalCargando(false);        

        }
        
    

    }


    /*Carga campos básicos por defecto */
    const cargaCampos = (values) => {

        let ret={};


        if (values.cliente !== undefined && values.cliente !== "") {

            let aux = values.cliente.split(";");
            let idCliente = aux[0];
            let idDepartamento = (aux[1] !== undefined) ? aux[1] : "0";
            

            //Buscamos el cliente
            let objCliente = clientes.filter(item => item.id === idCliente)[0];
            if (objCliente) {             

                    //Sacamos el departamento por defecto
                    //si ha marcado del select el departamento cogemos ese sino 

                    if (idDepartamento > 0) {
                        let dep = objCliente.departamentos.filter(i => i.id === idDepartamento)[0];
                        if (dep) {
                            ret.cp_origen = (dep.codigoPostal !== "") ? dep.codigoPostal : objCliente.codigoPostal;
                            //ret.cp_destino = (dep.codigoPostal !== "") ? dep.codigoPostal : objCliente.codigoPostal;

                            //Cambiamos el pais de origen y destino
                            if (dep.idPais > 0) {
                                ret.idPaisOrigen = dep.idPais;
                                ret.idPaisDestino = dep.idPais;
                            }

                        } else {
                            ret.cp_origen = objCliente.codigoPostal;
                            ret.cp_destino = objCliente.codigoPostal;
                        }
                    } else {

                        ret.cp_origen = objCliente.codigoPostal;
                        //ret.cp_destino = objCliente.codigoPostal;
                        ret.idPaisOrigen = paisDefecto.id;
                        ret.idPaisDestino = paisDefecto.id;
                    }
                

            }

        }

        return new Promise((resolve) => {

            resolve(ret);

        }
        );



    }

    const cargaServicios = async (values, setFieldValue) => {

        let ret={};

        if (values.cliente!==undefined && values.cp_origen!="")
        {

            let aux = values.cliente.split(";");

            let queryParams = {
                idCliente: aux[0],
                idPaisOrigen: values.idPaisOrigen ?? "",
                idPaisDestino: values.idPaisDestino ?? "",
                cp_origen: values.cp_origen ?? "",
                cp_destino: values.cp_destino ?? "",
                notransmitir: 1
            };

            if (aux.length > 1)
            {
                queryParams.idDepartamento = aux[1];
            }
            
            let params = new URLSearchParams(queryParams).toString();            

            setGlobalCargando(true);

            let response = await Api("get", `/simulador/get_datos?${params}`);
        
            if (response) {
            
                

                if (response.data) {
                    let { data } = response.data;

                    if (response.data.success === true) {

                        //Guardamos el objeto data
                        ret.objDatos=data;       
                                
                        //Sacamos las redes.                                
                        if(data.redes.length>0){                       
                            var red_d =data.redes.filter(i=>i.clienteRed.defecto==="1");                     
                            ret.selectRedes=data.redes.map(i=>{                                 
                                        return({idRed:i.clienteRed.idRed,nombreRed:i.clienteRed.nombreRed});                                 
                            });

                            
                        
                            if(red_d.length>0){
                                ret.red=red_d[0].clienteRed.idRed;
                                ret.portes = (red_d[0].clienteRed.portes != null) ? red_d[0].clienteRed.portes:"pagados";    
                                

                                if(ret.portes=="pagados" ){
                                    ret.servicios = red_d[0].servicios_tarifa_asociada;                                
                                }else{
                                    
                                    if (red_d[0].tarifa_debidos != undefined)
                                        ret.servicios = red_d[0].servicios_tarifa_debidos;
                                    else {
                                        ret.servicios = red_d[0].servicios_tarifa_asociada;                                
                                    }    
                                    
                                }                          

                                

                                if(ret.servicios.length>0){
                                    //Miramos si el servicio por defecto de la red, esta en los servicios disponibles
                                    let aux=ret.servicios.filter(i=>i.id===red_d[0].clienteRed.idServicio);

                                    if(aux.length==1)                            
                                        ret.servicio = red_d[0].clienteRed.idServicio; 
                                    else{
                                        ret.servicio=ret.servicios[0].id;
                                    } 
                            }
                                
                            }else{
                                ret.red = data.redes[0].clienteRed.idRed;
                                ret.portes = (data.redes[0].clienteRed.portes != null) ? data.redes[0].clienteRed.portes : "pagados";     
                                
                                red_d=[];
                                red_d[0]=data.redes[0];

                                if (ret.portes == "pagados") {
                                    ret.servicios = data.redes[0].servicios_tarifa_asociada;
                                } else {                                

                                    if (data.redes[0].tarifa_debidos != undefined)
                                        ret.servicios = data.redes[0].servicios_tarifa_debidos;
                                    else {
                                        ret.servicios = data.redes[0].servicios_tarifa_asociada;
                                    }    
                                }
                                                        

                                if (ret.servicios.length > 0) {

                                    //Miramos si el servicio por defecto de la red, esta en los servicios disponibles
                                    let aux = ret.servicios.filter(i => i.id === data.redes[0].clienteRed.idServicio);

                                    if (aux.length == 1)
                                        ret.servicio = data.redes[0].clienteRed.idServicio;
                                    else {
                                        ret.servicio = ret.servicios[0].id;
                                    }

                            }
                                                            
                            }

                                                                                                                                
                            let aux_servicios = [];
                            let select_servicios=[];
                            ret.selectServicios =[];

                        
                            if(ret.servicios.length>0){
                                ret.servicios.forEach(item=>{                                
                                        if(aux_servicios.indexOf(item.id)===-1){
                                            aux_servicios.push(item.id);

                                            let disabled = false;

                                            //si el rol del usuario no es de administración
                                            if (!esRolAdministrativo) {

                                                let serv = false;
                                                
                                                if (ret.portes == "pagados" ) {
                                                    serv = red_d[0].tarifa_asociada.tarifas_servicios.filter(i => i.idServicio === item.id);
                                                }else{
                                                    serv = red_d[0].tarifa_debidos.tarifas_servicios.filter(i => i.idServicio === item.id);
                                                }
                                            
                                                if (serv && serv != undefined) {
                                                    
                                                    if (serv[0].interno == "1" || serv[0].servicio.interno == "1") {
                                                        disabled = true;
                                                    }
                                                }
                                            }

                                            
                                            select_servicios.push({id:item.id,nombre:item.servicio,"disabled":disabled});

                                            if (item.id === ret.servicio) {
                                                //Asignamos una ruta por defecto
                                                ret.idRuta = item.idRuta;
                                            }
                                        }

                                    });

                            ret.selectServicios=select_servicios;
                            
                            }
                        }
                                    

                    }else{
                        ret=false;

                        if(response.data?.msg!=""){

                            Swal.fire(
                                'Error',
                                response.data.msg,
                                'warning'
                            )
                        }

                    }
                }

                setGlobalCargando(false);


            }

            return new Promise((resolve) => {

                resolve(ret);

            }
            );

        }


    }


    return {
        cargaSelects,
        cargaCampos,
        cargaServicios,
        handleSubmit

    }

}


/* Devuelve el factorVolumetrico correspondiente :
Jerarquia: RutaTarifa -> ServicioTarifa -> Tarifa -> ServicioRed->ClienteRed->Red
*/
export const CalculaFactorVolumetrico=(idRed,idServicio,idRuta,portes,redes)=>{

    
    const red= redes.find(i=>i.red.id===idRed);
    var tarifa={};
    var serviciosTarifa=[];
    var factorVolumetrico=250;
    var aux=[];
    var encontrado=false;

            
    if(red && parseInt(idServicio)>0 && parseInt(idRuta)>0){

         
        if(portes==="pagados"){
            tarifa=red.tarifa_asociada;
            serviciosTarifa=red.servicios_tarifa_asociada;
        }else{
            tarifa = red.tarifa_debidos;
            serviciosTarifa = red.servicios_tarifa_debidos;

            if(tarifa.id===undefined){
                tarifa = red.tarifa_asociada;
                serviciosTarifa = red.servicios_tarifa_asociada;
            }
        }
        
        //Sacamos el factor

        //1 Buscamos si la ruta tiene un factor.
        aux=serviciosTarifa.filter(i => i.id===idServicio && i.idRuta===idRuta);
        if(aux.length>0 && aux[0].factorVolumetrico && parseFloat(aux[0].factorVolumetrico)>0){
            factorVolumetrico=aux[0].factorVolumetrico;
            //Factor de la ruta o del servicio de tarifa
            encontrado=true;
        }

        //2 Cogemos el de servicio tarifa
        if(!encontrado && tarifa.tarifas_servicios){
            aux=tarifa.tarifas_servicios.filter(i=>i.idServicio===idServicio);
            if(aux.length>0 && parseFloat(aux[0].factorVolumetrico)>0){
                factorVolumetrico=aux[0].factorVolumetrico;
                encontrado=true;
            }

        }

            
        //3-Cogemos el de la tarifa
        if(!encontrado){            
            if(parseFloat(tarifa.factorVolumetrico)>0){
                factorVolumetrico=tarifa.factorVolumetrico;
                encontrado=true;
            }                        
        }


        //4 Cogemos el del servicio red
        if (!encontrado) {            

            aux=tarifa.tarifas_servicios.filter(i=>i.idServicio===idServicio);
            if(aux.length>0){
                if(parseFloat(aux[0].servicio.factorVolumetrico)>0){
                    factorVolumetrico = aux[0].servicio.factorVolumetrico;
                    encontrado=true;   
                }
            }            
        }

        //5 Cogemos el del cliente red
        if(!encontrado){
            if(parseFloat(red.clienteRed.factorVolumetrico)>0){
                factorVolumetrico = red.clienteRed.factorVolumetrico;
                encontrado=true;
            }
        }

        //6 Cogemos el de la red
        if(!encontrado){
            if(parseFloat(red.red.factor_volumetrico)>0){
                factorVolumetrico = red.red.factor_volumetrico;
                encontrado = true;                
            }             
        }

    }


    return factorVolumetrico;

}