import Yup from "app/librerias/yup";

export const validacionesEstandarGENEI = {
	validaciones: Yup.object({
		peso: Yup.number().default(1).required("Requerido"),
		bultos: Yup.number().positive("Los bultos deben ser mayor que cero").min("1", "Mínimo un bulto").default(1).required("Requerido"),
		direccion: Yup.object().shape(
			{
				nombre: Yup.string().required("El nombre debe estar relleno").default(""),
				direccion: Yup.string().required("La dirección debe estar rellena").default(""),
				localidad: Yup.string().required("La ciudad debe estar rellena").default(""),
				telefonoContacto: Yup.string().required("El teléfono debe estar rellenado").default(""),
				personaContacto: Yup.string().required("La persona de contacto debe estar rellenada").default(""),
				emailContacto: Yup.string().required("El email debe estar rellenado").default(""),
				cp: Yup.string().required("El código postal debe estar relleno").default(""),
                dni: Yup.string().required("El dni debe estar rellenado").default(""),
			},
			["nombre", "direccion", "localidad", "cp", "telefonoContacto", "personaContacto", "emailContacto"],
		),
		direccionRecogida: Yup.object().shape(
			{
				nombre: Yup.string().required("El nombre del remitente debe estar relleno").default(""),
				direccion: Yup.string().required("La dirección del remitente debe estar rellena").default(""),
				localidad: Yup.string().required("La ciudad del remitente debe estar rellena").default(""),
				cp: Yup.string().required("El código postal del remitente debe estar relleno").default(""),
				telefonoContacto: Yup.string().required("El teléfono de origen debe estar rellenado").default(""),
                personaContacto: Yup.string().required("La persona de contacto de origen debe estar rellenada").default(""),
                emailContacto: Yup.string().required("El email de origen debe estar rellenado").default(""),
                dni: Yup.string().required("El dni de origen debe estar rellenado").default(""),
			},
			["nombre", "direccion", "localidad", "cp", "telefonoContacto", "emailContacto"],
		)
	}),
    canalizacion: "oficina",
};