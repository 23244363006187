import AssignmentIcon from "@mui/icons-material/Assignment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import Etiqueta from "app/componentes/generales/Etiqueta";
import TienePermisos from "app/helpers/TienePermisos";
import { muestraPrecio, pintaOpcionesSelect } from "app/helpers/libFunciones";
import { envios } from "app/helpers/permisos";
import { useRoles } from "app/hooks/hooksPropios";
import { PreciosTrafico } from "app/modules/trafico/models/trafico.types";
import { capitalize, isEmpty } from "lodash";
import { ChangeEventHandler } from "react";
import { Link } from "react-router-dom";
import EtiquetaPeso from "./EtiquetaPeso";
import EtiquetasPrecios from "./EtiquetasPrecios";

interface EtiquetasEnvioProps {
	classes: any;
	datos: any;
	codExpedicion: string;
	precios: PreciosTrafico;
	filtros: any;
	desactivarMensajero: boolean;
	handleSubmitMensajero: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
	mensajeros: any[];
	handleCambioParcial: (datos: any) => void;
	tieneValoraciones: boolean;
}

export default function EtiquetasEnvio(props: EtiquetasEnvioProps) {
	const theme = useTheme() as any;

	const {
		classes,
		datos,
		codExpedicion,
		precios,
		filtros,
		desactivarMensajero,
		handleSubmitMensajero,
		mensajeros = [],
		handleCambioParcial,
		tieneValoraciones,
	} = props;

	const { esRolAdministrativo, esMensajero, esCliente } = useRoles();

	return (
		<div style={{ display: "flex", flexWrap: "wrap", marginRight: "5px", alignItems: "flex-start" }}>
			<Etiqueta
				texto={"E"}
				clases={classes.etiqueta}
				estilos={{ backgroundColor: theme.palette.trafico.envios.main, color: "white" }}
			/>
			{datos.numFactura && (
				<Etiqueta
					tooltip={`Nº de factura: ${datos.numFactura}`}
					texto={datos.numFactura}
					clases={classes.etiqueta}
					estilos={{ backgroundColor: "#248ef0", color: "white" }}
				/>
			)}
			
			{datos.distribucionPropia == "1" && (
				<Etiqueta
					texto={"Distribución Propia"}
					clases={classes.etiqueta}
					estilos={{ backgroundColor: "#646c9a", color: "white" }}
				/>
			)}
			<Etiqueta texto={datos.nombreRed} clases={classes.etiqueta} estilos={{ backgroundColor: "#000674", color: "white" }} />
			<Etiqueta texto={`Recogida: ${datos.idRecogida}`} clases={classes.etiqueta} />
			<Etiqueta texto={codExpedicion} clases={classes.etiqueta} />
			{!isEmpty(datos.codigoBarras) && (
				<Etiqueta texto={`CodBar: ${datos.codigoBarras}`} clases={classes.etiqueta} tooltip="Código de barras" />
			)}
			{!isEmpty(datos.referencia) && (
				<Etiqueta texto={`Ref: ${datos.referencia}`} clases={classes.etiqueta} tooltip="Referencia del envío" />
			)}
			<Etiqueta texto={datos.nombreServicio} clases={classes.etiqueta} />
			<Etiqueta texto={capitalize(datos.portes)} clases={classes.etiqueta} />
			<Etiqueta texto={`Bultos: ${datos.bultos}`} clases={classes.etiqueta} />
			{esRolAdministrativo && datos.pesoOriginal > 0 ? (
				<Etiqueta texto={`Peso ori. : ${datos.pesoOriginal} Kg`} clases={classes.etiqueta} tooltip="Peso original del envío" />
			) : null}

			<EtiquetaPeso id={datos.id} peso={datos.peso} className={classes.etiqueta} handleCambioParcial={handleCambioParcial} />
			{datos.contraReembolso > 0 && (
				<Etiqueta
					tooltip={
						datos.pagadoReembRed == "1" && datos.pagadoReembCliente == "0"
							? "Cobrado Red"
							: datos.pagadoReembCliente == "1"
							? "Pagado Cliente"
							: ""
					}
					texto={`Reemb.: ${muestraPrecio(datos.contraReembolso)} €`}
					estilos={
						datos.pagadoReembRed == "1" && datos.pagadoReembCliente == "0"
							? { backgroundColor: "red", color: "white" }
							: datos.pagadoReembCliente == "1"
							? { backgroundColor: "blue", color: "white" }
							: {}
					}
					clases={classes.etiqueta}
				/>
			)}

			<EtiquetasPrecios
				id={datos.id}
				precios={precios}
				tipoBeneficio={filtros?.tipoBeneficio}
				classes={classes}
				handleCambioParcial={handleCambioParcial}
				tieneValoraciones={tieneValoraciones}
			/>

			{(esRolAdministrativo || (esMensajero && TienePermisos([envios.ver_todos]))) && datos.distribucionPropia == "1" ? (
				<div className={classes.opcionesMensajero}>
					<Box mr={1}>
						<TextField
							disabled={desactivarMensajero}
							select
							name="idMensajero"
							onChange={handleSubmitMensajero}
							label=""
							value={datos.idMensajero || ""}
						>
							{pintaOpcionesSelect(mensajeros, "id", "nombreCompleto", "selectMensajerosTrafico")}
						</TextField>
					</Box>
				</div>
			) : datos.idMensajero > 0 && esMensajero ? (
				<Etiqueta texto={`${datos.nombreMensajero}`} clases={classes.etiqueta} />
			) : null}

			{esRolAdministrativo || esCliente ? (
				<Box display="flex">
					<div>
						<Link to={`/seguimiento/${datos.id}`} target="_blank">
							<Typography color="primary">
								<LocationOnIcon /> Seg
							</Typography>
						</Link>
					</div>

					{datos.enlace_seguimiento_externo && (
						<div style={{ marginLeft: "20px" }}>
							<a href={datos.enlace_seguimiento_externo} rel="noopener noreferrer" target="_blank">
								<Typography color="primary">
									<LocationOnIcon /> Seg. Red
								</Typography>
							</a>
						</div>
					)}
					{datos.urlPOD ? (
						<div style={{ marginLeft: "20px" }}>
							<a href={datos.urlPOD} rel="noopener noreferrer" target="_blank">
								<Typography color="primary">
									<AssignmentIcon /> POD
								</Typography>
							</a>
						</div>
					) : null}
				</Box>
			) : null}
		</div>
	);
}
