import { TIPOS_REFERENCIAS } from 'app/helpers/constants';
import { swalCondicional, swalError } from 'app/librerias/swal';
import { getNombreFicheroEtiquetaEnvio, revisar_aviso_kilometraje, setReferenciasParaAPI, setValoracionesParaAPI, setVariablesParaAPI } from 'app/modules/envios/helper/envios.helper';
import { isArray, isEmpty, isEqual, last } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import TienePermisos from '../helpers/TienePermisos';
import { base64ToBlob, copiaObjeto, ejecutaAPI, imprimeUrl, prepararDatosSchema, transformaEmptyStringToNull } from '../helpers/libFunciones';
import { recogidas } from '../helpers/permisos';
import { SelectMensajeros, SelectPaises } from '../helpers/selects';
import { ConfigMensajes } from '../pages/home/envios/config/ConfigMensajes';
import { ConfigServicios } from '../pages/home/envios/config/ConfigServicios';
import { schemaEnvios } from '../pages/home/envios/schemas';
import { getEnvio } from './api/envios/useApiEnvio';
import { useControlarErrores, useGlobalLoader, useRoles, useUsuarioConectado } from './hooksPropios';


export const FichaEnvioContext = React.createContext();

const reducerCliente = (state, action) => {

    switch (action.type) {
        case 'setDatosCliente':
          return {...state, cliente: action.payload};
        case 'setDireccionesHabituales':
          return {...state, direccionesHabituales: action.payload};
        case 'setDatosRecogida':
          return {...state, datosRecogida: action.payload};
        case 'setDatosEnvio':
            return {...state, datosEnvio: action.payload};
        case 'SET_DEFAULTS_ENVIO_FROM_RECOGIDA':

            if (state.datosEnvio.id !== "")
            {
                return {...state};
            }
            
            var datosEnvio = state.datosEnvio;

            datosEnvio.direccionRecogida = action.payload.direccion;

            datosEnvio.direccionRecogida.id = "";

            return {...state, datosEnvio: datosEnvio}
        case 'SET_PAISES': 
           return {...state, paises: action.payload}
        case 'SET_MENSAJEROS':
            return {...state, mensajeros: action.payload}
        case 'SET_SELECTOR_SERVICIOS':
            return {...state, serviciosSelector: action.payload}
        case 'SET_SELECTORES_ENVIOS': 
            return {...state, redesDisponibles: action.payload.redesDisponibles, redesSelector: action.payload.redesSelector, serviciosSelector: action.payload.serviciosSelector}
        case 'SET_SERVICIO_SELECCIONADO':
            
            var idServicio = action.payload;

            var datosGuardar = {
                codServicio: '',
                idRedMensaglobalDatos: '',
                configuracion: {}
            };

            if (idServicio)
            {
                //Buscamos el servicio en las redes

                state.redesDisponibles.forEach(red => {

                    var serviciosPagados = !isEmpty(red.tarifa_asociada) ? red.tarifa_asociada.tarifas_servicios : [];

                    var serviciosDebidos = !isEmpty(red.tarifa_debidos) ? red.tarifa_debidos.tarifas_servicios : [];

                    var mergeServicios = [...serviciosPagados, ...serviciosDebidos];

                    var infoServicio = mergeServicios.find(servicio => servicio.idServicio === idServicio);                    

                    if (infoServicio)
                    {
                        datosGuardar.codServicio = infoServicio.servicio.codigoDeServicioEnLaRed;                        
                        datosGuardar.idRedMensaglobalDatos = red.red.idRed;

                        if (datosGuardar.codServicio)
                        {

                            var configServiciosRed = ConfigServicios[datosGuardar.idRedMensaglobalDatos] ? ConfigServicios[datosGuardar.idRedMensaglobalDatos] : {};

                            datosGuardar.configuracion = configServiciosRed?.default || {};

                            if (configServiciosRed[datosGuardar.codServicio])
                            {
                                datosGuardar.configuracion = configServiciosRed[datosGuardar.codServicio];
                            }
                        }
                    }

                });
             
            }

            
                return { ...state, datosServicioSeleccionado: datosGuardar }
        
        case 'SET_AVISO':
            return { ...state, aviso: action.payload  }

            
        default:
          throw new Error();
      }
}

const defaultValuesEnvio = {
    cliente: {},
    direccionesHabituales: [],
    datosRecogida: {},
    datosEnvio: {},
    paises: [],
    mensajeros: [],
    mostrarBotonGuardarImprimir: false,
    redesDisponibles: [],
    redesSelector: [],
    serviciosSelector: [],
    servicioDefectoRed: "",
    datosServicioSeleccionado: {
        codServicio: '',
        idRedMensaglobalDatos: '',
        configuracion: {}
    },
    aviso: null
}

export const FichaEnvioProvider = ({children}) => {

    const [fichaEnvio, dispatchEnvio] = useReducer(reducerCliente, defaultValuesEnvio);

    return (
        <FichaEnvioContext.Provider value={[fichaEnvio, dispatchEnvio]}>
            {children}
        </FichaEnvioContext.Provider>
    )
}

export const useEnvios = () => {

    const [fichaEnvio, dispatchEnvio] = useContext(FichaEnvioContext);

    const location = useLocation();

    const {setGlobalCargando} = useGlobalLoader();

    const usuarioConectado = useUsuarioConectado();

    const {esCliente, esRolAdministrativo, esMensajero} = useRoles();

    

    const history = useHistory();

    const {errores } = useControlarErrores();

    const imagenesMensajero = useMemo(() => {

        let devolver = [];

        if (!isEmpty(fichaEnvio.datosEnvio) && fichaEnvio.datosEnvio?.estados?.length > 0)
        {
            fichaEnvio.datosEnvio.estados.forEach(estado => {
                
                if (estado.imagenes.length > 0)
                {
                    devolver = [...devolver, ...estado.imagenes];
                }

            })
        }

        return devolver;

    }, [fichaEnvio.datosEnvio])

    const handleSetDatosEnvio = useCallback((infoEnvio, variablesRed = []) => {

        var referenciasEnvio = infoEnvio.referencias;

        var valoracionesEnvio = infoEnvio.valoraciones;

        delete infoEnvio.referencias;

        delete infoEnvio.valoraciones;

        var referenciasAux = [];
        
        /*
            Alejandro 05/06/2023
            Las referencias de tipo variable guardan el atributo 'variable' como peso. Por tanto al traerlo de la DB
            hay que ajustar que el valor de referencia.peso, se ponga en el atributo 'variable'
        */

        referenciasEnvio && referenciasEnvio.forEach((referencia) => {

            referenciasAux.push({
                id: referencia.id,
                bultos: referencia.unidades,
                descripcion: referencia.descripcion,
                peso: parseInt(referencia.idTipo) !== TIPOS_REFERENCIAS.VARIABLE ? referencia.peso : 1,
                alto: referencia.alto,
                ancho: referencia.ancho,
                largo: referencia.largo,
                peso_original: referencia.peso_original,
                alto_original: referencia.alto_original,
                ancho_original: referencia.ancho_original,
                largo_original: referencia.largo_original,
                idTipo: referencia.idTipo,
                factorVolumetrico: referencia.factorVolumetrico,
                pesoVolumetrico: referencia.pesoVolumetrico,
                idReferencia: referencia.idServicioReferencia,
                referencia:referencia.referencia,
                variable: parseInt(referencia.idTipo) === TIPOS_REFERENCIAS.VARIABLE ? referencia.peso : 1
            });

        });

        infoEnvio.referencias = referenciasAux;

        infoEnvio.variables = {
            variablesCampos: [],
            variablesRed: variablesRed,
            variablesOpcionales: infoEnvio.variables && infoEnvio.variables.map(variable => {
                return {
                    idVariable: variable.idVariable,
                    valor: variable.valor
                }
            })
        }

        valoracionesEnvio && valoracionesEnvio.forEach(valoracion => {

            valoracion.ref = valoracion.referencia;

            delete valoracion.referencia;

        });

        infoEnvio.valoraciones = valoracionesEnvio;

        infoEnvio.direccion.habitual = infoEnvio.direccion.habitual === "1" ? true : false;

        infoEnvio.direccionRecogida.habitual = infoEnvio.direccionRecogida.habitual === "1" ? true : false;

        infoEnvio.distribucionPropia = infoEnvio.distribucionPropia === "1" ? true : false;

        infoEnvio.oficinaRed = infoEnvio.oficinaRed ? infoEnvio.oficinaRed : {};

        infoEnvio.idDireccionDestinoSeleccionada = "";

        infoEnvio.referenciasVariables= [];

        dispatchEnvio({type: "setDatosEnvio", payload: infoEnvio});

    }, [dispatchEnvio]);

    const handleSetDatosRecogida = useCallback(async (respuestaRecogida) => {

        respuestaRecogida.direccion.habitual = respuestaRecogida.direccion.habitual === "1" ? true : false;
        

        dispatchEnvio({
            type: "SET_DEFAULTS_ENVIO_FROM_RECOGIDA",
            payload: {
                direccion: respuestaRecogida.direccion
            }
        });

        //Sacar los datos del cliente.

        var datosCliente = await ejecutaAPI('GET', `clientes/${respuestaRecogida.idCliente}`);

    
        if (datosCliente.success)
        {

            var respuestaCliente = datosCliente.respuesta;

            dispatchEnvio({type: "setDatosCliente", payload: respuestaCliente});

            //Filtrar las direcciones de los departamentos del cliente por las habituales y setearlas en el estado.

            let direccionesCliente = [];
            
            respuestaCliente.departamentos.forEach((dpto) => {

                let direccionesHabituales = dpto.direcciones.filter((dir) => dir.habitual === "1");


                direccionesCliente = [...direccionesCliente, ...direccionesHabituales];
            });

            dispatchEnvio({type: "setDireccionesHabituales", payload: direccionesCliente});

        }

        dispatchEnvio({type: "setDatosRecogida", payload: respuestaRecogida});

    },[dispatchEnvio]);


    const cargarEnvio = useCallback(async (id = "") => {


        if (id !== "new" && id !== "")
        {

            var datosEnvioRespuesta = await ejecutaAPI('GET',`envios/${id}`);

            if (datosEnvioRespuesta.success)
            {

               handleSetDatosEnvio(datosEnvioRespuesta.respuesta);
                
                return datosEnvioRespuesta.respuesta;

            }
        }
        else 
        {
            var idRecogidaInicial = "";      

            idRecogidaInicial = location.state?.idRecogida || location.search.replace("?","");
                            
            var camposIniciales = schemaEnvios.cast();            

            camposIniciales.idRecogida = idRecogidaInicial; 
            
            camposIniciales.recogidaFueraPlaza = location.state?.recogidaFueraPlaza || false;

            camposIniciales.idMensajero = esMensajero ?  usuarioConectado.id : null;

            camposIniciales.documentosRed = [];

            if (camposIniciales.recogidaFueraPlaza && location.state?.datosRecogida)
            {

                //Datos recogida fuera de plaza
                var datosRecogida=location.state.datosRecogida;

                camposIniciales.idRecogida=-1;
                camposIniciales.direccionRecogida=datosRecogida.direccion;
                camposIniciales.idDepartamentoRecogida=datosRecogida.idDepartamento;
                camposIniciales.fechaRecogida=datosRecogida.fecha;
                camposIniciales.observacionesRecogida=datosRecogida.observacionesRecogida;
                camposIniciales.horaRecogidaDesde=datosRecogida.horaDesde;
                camposIniciales.horaRecogidaHasta=datosRecogida.horaHasta;
                camposIniciales.preparadoRecogida=datosRecogida.preparado;
                camposIniciales.id="new";
                camposIniciales.idClientes=datosRecogida.idCliente;

                setGlobalCargando(true);
                 await handleSetDatosRecogida(datosRecogida);
                setGlobalCargando(false);
                                

            }

          
            camposIniciales.portes = "pagados";

            camposIniciales.referencias = [];
            
            camposIniciales.variables = {
                variablesRed: [],
                variablesCampos: [],
                variablesOpcionales: []
            }

            camposIniciales.referenciasVariables= [];

            camposIniciales.valoraciones = [];
          

            dispatchEnvio({type: "setDatosEnvio", payload: camposIniciales});

            return camposIniciales;
        }


    }, [handleSetDatosEnvio,handleSetDatosRecogida, setGlobalCargando, dispatchEnvio, location, esMensajero, usuarioConectado])

    const cargarRecogida = useCallback(async (idRecogida) => {


        var datosRecogida = await ejecutaAPI('GET',`recogidas/${idRecogida}`);

        if (datosRecogida.success)
        {

            var respuestaRecogida = datosRecogida.respuesta;

            handleSetDatosRecogida(respuestaRecogida);

            return respuestaRecogida;

        }

    }, [handleSetDatosRecogida]);

    const imprimeEnvio = useCallback((datosEnvio = null) => {

        //Obtener los datos del envío.

        let fichaEnvioEtiqueta = datosEnvio || fichaEnvio.datosEnvio;

        let etiquetasImprimir = fichaEnvioEtiqueta?.PDFEtiquetasBase64;

        if (etiquetasImprimir)
        {  

            var blob = base64ToBlob(etiquetasImprimir, "application/pdf");

            var blobUrl = URL.createObjectURL(blob);
            
            //Si es móvil, descargamos el fichero directamente, si no, sacamos la pantalla de imprimir
            if (isMobile)
            {
                const link = document.createElement('a');

                link.download = getNombreFicheroEtiquetaEnvio(fichaEnvioEtiqueta);

                link.href = blobUrl;

                link.target = "_blank";

                document.body.append(link);

                link.click();
                
                link.remove();

                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            }
            else 
            {
                imprimeUrl(blobUrl);
            }

        }
        else 
        {
            Swal.fire({
                title: 'Aviso',
                text: 'El envío no tiene etiquetas generadas',
                icon: 'info'
            });

        }
        
        

        setGlobalCargando(false);

    }, [fichaEnvio.datosEnvio, setGlobalCargando])

    const redireccionarEnvio = useCallback((guardarEnvios, accionBoton) => {

        var urlRedireccionar = `/envios/${guardarEnvios.respuesta.id}`;

        let historyParams = {};

        if (accionBoton === "ir_recogida" || accionBoton === "ir_recogida_imprimir" || accionBoton === "guardar_sin_transmitir")
        {

            let paramImprimir = {}

            if (TienePermisos([recogidas.ver]))
            {            
                //Si tiene permiso para ver la recogida, establecemos el paramétro que necesita la ficha de recogida

                var idsImprimir = (guardarEnvios.respuesta?.id) ? [guardarEnvios.respuesta.id] : guardarEnvios.respuesta?.idsEnvios;

                paramImprimir = { idsEnviosImprimir: idsImprimir};

                if (guardarEnvios.respuesta.verRecogida == "1")
                {
                    urlRedireccionar = `/recogidas/${guardarEnvios.respuesta.idRecogida}`;
                }
                else
                {
                    urlRedireccionar = `/recogidas/${guardarEnvios.respuesta.idRecogida}/envios`;
                }

                
            }

            if (accionBoton === "ir_recogida_imprimir")
            {
                historyParams = paramImprimir;
            }
        }
        else if (accionBoton === "ir_trafico")
        {
            urlRedireccionar = esMensajero ? '/mensajero/trafico' : `/trafico`;
        }
        else if (accionBoton === "nuevo_misma_recogida")
        {
            urlRedireccionar = `/envios/new`;

            historyParams = {
                idRecogida: guardarEnvios.respuesta.idRecogida
            }
        }
        else if (accionBoton === "guardar_imprimir")
        {
            imprimeEnvio(guardarEnvios.respuesta);
        }
        else if (accionBoton === "nuevo_distinto_cliente")
        {
            historyParams = {
                nuevoCliente: true
            }
        }
        else if (accionBoton === "guardar_imprimir_albaran")
        {
            historyParams = {
                generarAlbaran: true
            }
        }

        history.push(urlRedireccionar, historyParams);



    }, [history, imprimeEnvio, esMensajero])

    const comparaValoraciones = useCallback((valoracionesActuales, valoracionesAnteriores) => {

        var devolver = false;

        if (fichaEnvio.datosEnvio.facturado)
        {

            if (valoracionesActuales.length === valoracionesAnteriores.length)
            {

                valoracionesActuales.forEach(valoracion => {

                    var objetoComparar = {
                        coste: parseFloat(valoracion.coste).toFixed(2),
                        precio: parseFloat(valoracion.precio).toFixed(2)
                    };

                    //Buscamos si podemos encontrar un objeto similar en las valoraciones anteriores

                    var encontrado = false;

                    valoracionesAnteriores.forEach(valoracionAnterior => {

                        var objetoValoracionAnterior = {
                            coste: parseFloat(valoracionAnterior.coste).toFixed(2),
                            precio: parseFloat(valoracionAnterior.precio).toFixed(2)
                        }

                        var esIgual = isEqual(objetoComparar, objetoValoracionAnterior);

                        if (esIgual)
                        {
                            encontrado = true;
                        }


                    });
                    
                    if (!encontrado)
                    {
                        devolver = true;
                    }

                });

            }
            else 
            {
                devolver = true;
            }

        }


        return devolver;
    }, [fichaEnvio.datosEnvio.facturado]);

    const procesaGuardadoEnvio = useCallback(async (verbo, url, datosEnviar, values) => {

        setGlobalCargando(true);
 
        var guardarEnvios = await ejecutaAPI(verbo, url, datosEnviar);
 
        if (guardarEnvios.success)
        {                   
            
            if (guardarEnvios.respuesta?.id)
            {
                handleSetDatosEnvio(guardarEnvios.respuesta, values.variables.variablesRed);
            }

            if (guardarEnvios.respuesta.etiquetasGeneradas && datosEnviar.id)
            {
                Swal.fire({
                    title: 'Aviso',
                    text: 'Se han modificado las etiquetas, recuerde reetiquetar',
                    icon: 'info',
                    showConfirmButton: true,
    
                }).then(() => {
                    redireccionarEnvio(guardarEnvios, values.submitter);
                });
            }
            else 
            {
                if (datosEnviar.id > 0)
                {
                    Swal.fire({
                        title: 'Actualizado',
                        text: "Se ha modificado el envio",
                        icon: 'success',
                    });
                }
                
                redireccionarEnvio(guardarEnvios, values.submitter);
                
            }   

            
        }
        else 
        {

            var msgError = 'Se ha producido un error guardando el envío.';
         
            if (guardarEnvios.respuesta !== undefined)
            {
                if (guardarEnvios.respuesta.data !== undefined)
                {
                    msgError = guardarEnvios.respuesta.data.msg.join("<br>");
                }
            }


            Swal.fire({
                title: 'Error',
                html: msgError,
                icon: 'error',

            });
        }

        setGlobalCargando(false);

    }, [handleSetDatosEnvio, setGlobalCargando, redireccionarEnvio]);

    const guardarEnvio = useCallback (async (values) => {

        var objetoTratar = copiaObjeto(values);

        var nuevasReferencias = setReferenciasParaAPI(objetoTratar, fichaEnvio.redesDisponibles);

        var variables = setVariablesParaAPI(values);
                
        var valoraciones = setValoracionesParaAPI(values);

        var valoracionesModificadas = false;

        var schema = schemaEnvios.cast();

        var datosEnviar = prepararDatosSchema(values, schema);

        delete datosEnviar.idDireccionDestinoSeleccionada;

        datosEnviar.recogidaFueraPlaza = (datosEnviar.recogidaFueraPlaza===true) ? "1" : "0";

        datosEnviar.referencias = nuevasReferencias;

        datosEnviar.variables = variables;

        datosEnviar.valoraciones = valoraciones;

        datosEnviar.documentosRed = objetoTratar?.documentosRed?.map(documento => documento.id) || null;

        if (values.submitter === "guardar_sin_transmitir")
        {
            datosEnviar.transmitir = "0";
        }

        var verbo = 'POST';

        var url = `envios`;

        if (datosEnviar.id != undefined && datosEnviar.id !== "" && datosEnviar.id > 0)
        {

            verbo = 'PUT';

            url = `envios/${datosEnviar.id}`;

            valoracionesModificadas = comparaValoraciones(valoraciones, fichaEnvio.datosEnvio.valoraciones);

        }

       var objMsg = null; 

       if (fichaEnvio?.datosServicioSeleccionado?.idRedMensaglobalDatos>0)
       {        
            //Comprobamos si tiene avisos a mostrar.
           if (ConfigMensajes[fichaEnvio.datosServicioSeleccionado.idRedMensaglobalDatos] != undefined)
           {
               objMsg = ConfigMensajes[fichaEnvio.datosServicioSeleccionado.idRedMensaglobalDatos][fichaEnvio.datosServicioSeleccionado.codServicio] != undefined ? ConfigMensajes[fichaEnvio.datosServicioSeleccionado.idRedMensaglobalDatos][fichaEnvio.datosServicioSeleccionado.codServicio]:null;
           }
        }

        let avisarNoTransmision = values.submitter === "guardar_sin_transmitir";

        let avisarKilometraje = revisar_aviso_kilometraje(datosEnviar);

        let continuar = await swalCondicional({
            title: 'ATENCIÓN',
            text: 'El envío no se transmitirá a la red de transporte, ¿Desea continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        }, avisarNoTransmision);

        if (!continuar)
        {
            return;
        }
        
        continuar = await swalCondicional({
            title: 'ATENCIÓN',
            text: 'Está creando una recogida fuera de plaza para el día de hoy y es posible que le cobren kilometraje, continuar con la fecha del mismo día implica la aceptación de los kilómetros si los hubiera. Si tiene dudas consulte con su agencia.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        }, avisarKilometraje)

        if (!continuar)
        {
            return;
        }

        if (valoracionesModificadas && esRolAdministrativo)
        {

            var numFactura = fichaEnvio.datosEnvio.numFactura || '';
            
            Swal.fire({
                title: 'Envío facturado',
                text: `La valoración del envío ha cambiado, si continúa tendrá que modificar la factura ${numFactura}. ¿Continuar?`,
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Continuar'

            }).then(async (result) => {

                if (result.value)
                {

                    procesaGuardadoEnvio(verbo, url, datosEnviar, values);

                }

            });
        }
        else 
        {

            if (objMsg !== null) 
            {
                Swal.fire(objMsg).then(async (result) => {

                    if (result.value) 
                    {

                        if (fichaEnvio.aviso !== null)
                        {

                            Swal.fire(fichaEnvio.aviso).then(async (result) => {

                                if (result.value) {

                                    procesaGuardadoEnvio(verbo, url, datosEnviar, values);

                                }

                            });

                        }
                        else
                        {

                            procesaGuardadoEnvio(verbo, url, datosEnviar, values);
                        }

                    }

                });


            }
            else
            {

                
                if (fichaEnvio.aviso !== null) 
                {

                    Swal.fire(fichaEnvio.aviso).then(async (result) => {

                        if (result.value) 
                        {

                            procesaGuardadoEnvio(verbo, url, datosEnviar, values);

                        }

                    });

                } 
                else
                {

                    procesaGuardadoEnvio(verbo, url, datosEnviar, values);
                }
            }
        }


    }, [comparaValoraciones, procesaGuardadoEnvio, esRolAdministrativo, fichaEnvio.datosEnvio,fichaEnvio.redesDisponibles,fichaEnvio.datosServicioSeleccionado,fichaEnvio.aviso]);


    const cargarPaises = useCallback(async () => {

        try {
            
            let paisesAPI = await SelectPaises();

            if (paisesAPI)
            {

                dispatchEnvio({type: "SET_PAISES", payload: paisesAPI});

            }


        } catch(e)
        {

        }

    },[dispatchEnvio]);

    const cargarMensajeros = useCallback(async () => {

        
        let usuarios= await SelectMensajeros();

        let arrayMensajeros = [];

        arrayMensajeros = usuarios.map((mensajero) => {

            return {id: mensajero.id, value: `${mensajero.nombre} ${mensajero.apellidos}`}
        });

        dispatchEnvio({type: 'SET_MENSAJEROS', payload: arrayMensajeros});

        

    }, [dispatchEnvio]);

    const guardarDistribucion = async (values) => {

        var datosEnviar = transformaEmptyStringToNull(values);

        setGlobalCargando(true);
 
        var {success, respuesta} = await ejecutaAPI('PUT', `envios/${datosEnviar.id}`, datosEnviar);

        if (success)
        {

            handleSetDatosEnvio(respuesta);

            Swal.fire({
                title: 'Exito',
                text: 'Se ha realizado la acción correctamente',
                icon: 'success',

            });
        }
        else 
        {
            Swal.fire({
                title: 'Error',
                text: 'Se ha producido un error',
                icon: 'error',

            });
        }

        setGlobalCargando(false);

    }

    const guardaImagenes = async (imagenes) => {

        setGlobalCargando(true);

        var datosEnviar = {
            imagenes: imagenes.map(imagen => imagen.id)
        };


        const {success, respuesta} = await ejecutaAPI('PUT', `envios/${fichaEnvio.datosEnvio.id}`, datosEnviar);


        if (success)
        {

            Swal.fire({
                title: 'Exito',
                text: 'Se ha realizado la acción correctamente',
                icon: 'success',
                showConfirmButton: true,

            }).then(() => {

                handleSetDatosEnvio(respuesta);


            });

        }
        else 
        {
            Swal.fire({
                title: 'Error',
                text: 'Se ha producido un error guardando las imágenes.',
                icon: 'error'
            });
        }

        setGlobalCargando(false);

    }

    const anularEnvio = (values, duplicar = false) => {

        Swal.fire({
            title: `Cancelar envío`,
            text: 'Se cancelará el envío. ¿Continuar?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar'

        }).then(async (result) => {

            setGlobalCargando(true);

            if (result.value)
            {
                const {success, respuesta, msg} = await ejecutaAPI('PUT', `envios/${fichaEnvio.datosEnvio.id}/anular`);
                
                setGlobalCargando(false);

                if (success)
                {

                    Swal.fire({
                        title: 'Exito',
                        text: 'Se ha realizado la acción correctamente',
                        icon: 'success',
                        showConfirmButton: true,
                        }).then(() => {
                            
                            if (msg && isArray(msg) && msg.length > 0) {

                                Swal.fire({
                                    title: 'Error',
                                    text: msg.join(","),
                                    icon: 'warning'
                                });
                                
                            }

                            if (duplicar)
                            {
                                duplicarEnvio(respuesta, values.variables.variablesRed);
                            }
                            else 
                            {
                                handleSetDatosEnvio(respuesta, values.variables.variabesRed);
                            }
                        });
                }
                else 
                {
                    let errorMsg = "Se ha producido un error anulando el envío";

                    if (respuesta.data.msg) {
                        errorMsg = respuesta.data.msg.join(",");
                    }

                    swalError('Error', errorMsg);

                }
            }

            setGlobalCargando(false);

        });


    }

    //TODO: Refactorizar todo el tema de anular/duplicar/clonar cuando se cambie la ficha del envío a React Query y React Hook Form
    const duplicarEnvio = async (datosFichaEnvio = {}, variablesRed = {}) => {

        let datosEnvio = isEmpty(datosFichaEnvio) ? {} : datosFichaEnvio;

        if (isEmpty(datosEnvio))
        {

            setGlobalCargando(true);

            try {

                const respuesta = await getEnvio(fichaEnvio.datosEnvio.id);

                datosEnvio = respuesta;

            }
            catch(e)
            {
                swalError('Error', 'Se ha producido un error obteniendo los datos del envío');
            }

            setGlobalCargando(false);
        }

        if (isEmpty(datosEnvio))
        {
            return;
        }

        datosEnvio.id = "";

        datosEnvio.direccion.id = "";

        datosEnvio.direccionRecogida.id = "";

        datosEnvio.editable = true;

        datosEnvio.codExpedicion = "";

        datosEnvio.anulado = "0";

        datosEnvio.fechaTransmitidoRed = null;

        datosEnvio.fechaCreacion = null;

        datosEnvio.fechaActualizacion = null;

        datosEnvio.codExpedicionUniversal = null;

        datosEnvio.codExpedicionInternacional = null;

        datosEnvio.PDFEtiquetasBase64 = "";

        datosEnvio.PDFEtiquetas = "";

        datosEnvio.codigoBarras = "";

        datosEnvio.transmitible = "1";

        datosEnvio.logReembolsos = [];

        datosEnvio.referencias.forEach((referencia, index) => {

            delete datosEnvio.referencias[index].id;

            delete datosEnvio.referencias[index].idEnvio;


        });

        datosEnvio.valoraciones.forEach((valoracion, index) => {

            delete datosEnvio.valoraciones[index].id;

            delete datosEnvio.valoraciones[index].idEnvio;


        });


        handleSetDatosEnvio(datosEnvio, variablesRed);

        window.history.replaceState(null, "", `/envios/new`);



    }


    const imprimirEtiquetaPosicion = useCallback(async (formato) => {

        setGlobalCargando(true);

        
        if (fichaEnvio?.datosEnvio) {

            const data = { "envios": [fichaEnvio.datosEnvio.id], "formato": formato };

            const { success, respuesta } = await ejecutaAPI('POST', `envios/etiquetas`, data);

            if (success) {

                if (respuesta.PDFEtiquetasBase64) {

                    var blob = base64ToBlob(respuesta.PDFEtiquetasBase64, "application/pdf");

                    var blobUrl = URL.createObjectURL(blob);

                    imprimeUrl(blobUrl);

                }

            }
            else {

                var error = respuesta.data?.msg?.join("<br>");

                if (!error) {
                    error = "Se ha producido un error obteniendo las etiquetas";
                }

                Swal.fire({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                });
            }
        }
        else {
            Swal.fire({
                title: 'Error',
                text: 'No se han filtrado envíos',
                icon: 'error'
            });
        }

        setGlobalCargando(false);

    }, [setGlobalCargando,fichaEnvio.datosEnvio]);

    const esEditable = () => {

        var editable = true;
       
        var infoEstadoRecogida = last(fichaEnvio.datosRecogida.estados);

        if (fichaEnvio.datosEnvio.editable !== undefined && !fichaEnvio.datosEnvio.editable)
        {
            editable = false;
        }

        if (fichaEnvio.datosEnvio.anulado === "1")
        {
            editable = false;
        }

        if (esCliente || esMensajero)
        {

            if (fichaEnvio.datosEnvio.facturado)
            {
                editable = false;
            }

            if (infoEstadoRecogida && (infoEstadoRecogida.codigo === "recogido" || infoEstadoRecogida.codigo === "terminado"))
            {
                editable = false;
            }

            
            
        }else{
            //si esta transmitido a la red y la fecha de transmision a la red es anterior a hoy podemos editar
            if (fichaEnvio.datosEnvio.anulado !== "1" && fichaEnvio.datosEnvio.RedTransmitible && fichaEnvio.datosEnvio.fechaTransmitidoRed != "" && fichaEnvio.datosEnvio.fechaTransmitidoRed != null){
                if (moment(moment().format("YYYY-MM-DD")).diff(moment(fichaEnvio.datosEnvio.fechaTransmitidoRed).format("YYYY-MM-DD"), 'days')  >=1 ){
                    editable=true;                    
                }
            } else if (fichaEnvio.datosEnvio.fechaTransmitidoRed == null || fichaEnvio.datosEnvio.fechaTransmitidoRed == "" )  {
                   editable=true;
            }  
        }   

        return editable;

    }

    const esAnulable = () => {

        var anulable = true;

        var infoEstadoRecogida = last(fichaEnvio.datosRecogida.estados);

        if (fichaEnvio.datosEnvio.anulado === "1" || fichaEnvio.datosEnvio.codExpedicion === "" || fichaEnvio.datosEnvio.id === "" || fichaEnvio.datosEnvio.recogidaFueraPlaza==="1")
        {
            anulable = false;
        }

        if (esCliente || esMensajero)
        {
            if (fichaEnvio.datosEnvio.facturado)
            {
                anulable = false;
            }

            if (infoEstadoRecogida)
            {

                if (infoEstadoRecogida.codigo === "recogido" || infoEstadoRecogida.codigo === "terminado")
                {
                    anulable = false;
                }

            }
        }

        return anulable;
    }

    

    var schema = schemaEnvios;
 
    if (fichaEnvio.datosServicioSeleccionado.configuracion.validaciones)
    {
        schema = schemaEnvios.concat(fichaEnvio.datosServicioSeleccionado.configuracion.validaciones);
    }
        

    return {
        cargarEnvio,
        cargarRecogida,
        cargarPaises,
        cargarMensajeros,
        guardarEnvio,
        guardarDistribucion,
        guardaImagenes,
        anularEnvio,
        imprimeEnvio,
        imprimirEtiquetaPosicion,
        esEditable,
        esAnulable,
        schemaEnvios: schema,
        errores,
        dispatchEnvio,
        fichaEnvio,
        imagenesMensajero,
        esRolAdministrativo,
        duplicarEnvio
        
    }

}