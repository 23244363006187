import Yup from "app/librerias/yup";

export const validacionesEstandarONTIMEGTS = {
	validaciones: Yup.object({
		peso: Yup.number().default(1).required("Requerido"),
		bultos: Yup.number().positive("Los bultos deben ser mayor que cero").min("1", "Mínimo un bulto").default(1).required("Requerido"),
		direccion: Yup.object().shape(
			{
				nombre: Yup.string().required("El nombre debe estar relleno").default(""),
				direccion: Yup.string().required("La dirección debe estar rellena").default(""),
				localidad: Yup.string().required("La ciudad debe estar rellena").default(""),
				cp: Yup.string().required("El código postal debe estar relleno").default(""),
			},
			["nombre", "direccion", "localidad", "cp", "telefonoContacto", "personaContacto", "emailContacto"],
		),
		direccionRecogida: Yup.object().shape(
			{
				nombre: Yup.string().required("El nombre del remitente debe estar relleno").default(""),
				direccion: Yup.string().required("La dirección del remitente debe estar rellena").default(""),
				localidad: Yup.string().required("La ciudad del remitente debe estar rellena").default(""),
				cp: Yup.string().required("El código postal del remitente debe estar relleno").default(""),
			},
			["nombre", "direccion", "localidad", "cp", "telefonoContacto", "emailContacto"],
		)
	}),
    canalizacion: "oficina",
};