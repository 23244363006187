
import { Box, CircularProgress, SxProps } from '@mui/material';
import { isArray } from 'lodash';
import { ReactNode } from 'react';

const overlayStyle = {
    position: 'absolute',
    inset: '0',
    background: '#dbdbdb33',
    zIndex: '1'
}

interface CargandoProps {
    activo: any;
    showLoader?: boolean;
    overlay?: boolean;
    children?: ReactNode;
    styleBox?: SxProps;
}

const Cargando = ({activo = false, showLoader = true, overlay = false, children, styleBox = {}} : CargandoProps) => {

    
    if (!isArray(activo))
    {
        activo = [activo];
    }

    const encontrado = activo.some((act: string | boolean) => ['idle', 'loading', true].includes(act));

    activo = encontrado ? true : false;


    if (activo && !showLoader)
    {
        return null;
    }

    return ( 
        <>
            {
                activo
                ?
                (
                    overlay 
                    ?
                    (
                        <>
                            <Box sx={overlayStyle}>
                                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                                    <CircularProgress />
                                </Box>
                            </Box>
                            {children}
                        </>
                    )
                    :
                    (
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" sx={styleBox}>
                            <CircularProgress />
                        </Box>
                    )
                    
                )
                :
                (
                    children
                )
            }
        </>
     );
}
 
export default Cargando;