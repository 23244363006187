import { filtraServiciosVinculados } from "app/componentes/envios/VariablesRed/FuncionesVariables";
import { calcularComisionDesdePorcentaje } from "app/helpers/comisiones";
import { IMPORTE_FIJO_DEBIDOS, TIPOS_REFERENCIAS } from "app/helpers/constants";
import { sumaColumnaArray } from "app/helpers/libFunciones";
import { calculaTotalValoraciones } from "app/helpers/valoraciones";
import { getDatosEnvios } from "app/hooks/api/simulador/useGetDatosEnvios";
import moment from "app/librerias/moment";
import { CalculaFactorVolumetrico } from "app/pages/home/simulador/FuncionesSimulador";
import { isEqual, parse, startOfDay } from "date-fns";
import { cloneDeep, isEmpty } from "lodash";

export const calculaPortesDebidos = (valoraciones = [], tieneTarifaDebidos = false) => {
	let importe = null;

	if (valoraciones.length > 0) {
		importe = sumaColumnaArray(valoraciones, "precio");

		if (!tieneTarifaDebidos) {
			importe += IMPORTE_FIJO_DEBIDOS;
		}

		importe = importe.toFixed(2);
	}

	return importe;
};

export const setVariablesParaAPI = (values) => {
	let variables = values.variables.variablesOpcionales;

	//A las variables opcionales le añadimos , las fijas para que las guarde, por si tienen acción en la red
	var variablesFijas = [];

	if (values.valoraciones?.length > 0) {
		values.valoraciones
			.filter((item) => item.idVariable > 0)
			.forEach((item) => {
				if (
					variables.filter((var_item) => var_item.idVariable == item.idVariable).length == 0 &&
					variablesFijas.filter((var_item) => var_item.idVariable == item.idVariable).length == 0
				) {
					variablesFijas.push({ idVariable: item.idVariable, valor: null });
				}
			});
	}

	variables = variables.concat(variablesFijas);

	return variables;
};

export const setReferenciasParaAPI = (values, redesDisponibles) => {
	let referencias = [];

	var factorVolumetricoDefecto = CalculaFactorVolumetrico(
		values.idRed,
		values.idServicio,
		values.idRuta,
		values.portes,
		redesDisponibles,
	);

	values.referencias.forEach((referencia) => {
		var refAux = {
			id: referencia.id,
			unidades: referencia.bultos,
			idServicioReferencia: referencia.idReferencia,
			peso: parseInt(referencia.idTipo) === TIPOS_REFERENCIAS.VARIABLE ? referencia.variable : referencia.peso,
			alto: referencia.alto,
			ancho: referencia.ancho,
			largo: referencia.largo,
			pesoVolumetrico: referencia.pesoVolumetrico,
			factorVolumetrico: referencia.factorVolumetrico != null ? referencia.factorVolumetrico : factorVolumetricoDefecto,
			descripcion: referencia.descripcion,
		};

		referencias.push(refAux);
	});

	return referencias;
};

export const setValoracionesParaAPI = (values) => {
	let devolver = values.valoraciones.map((valoracion) => {
		var objetoValoracion = {
			referencia: valoracion.ref,
			servicio: valoracion.servicio,
			coste: isNaN(valoracion.coste) ? 0 : valoracion.coste,
			precio: isNaN(valoracion.precio) ? 0 : valoracion.precio,
			info: valoracion.info,
			infoSinCoste: valoracion.infoSinCoste,
			idRef: isNaN(valoracion.idRef) || parseInt(valoracion.idRef) === 0 ? null : valoracion.idRef,
			idVariable: isNaN(valoracion.idVariable) || parseInt(valoracion.idVariable) === 0 ? null : valoracion.idVariable,
			idServicio: isNaN(valoracion.idServicio) || parseInt(valoracion.idServicio) === 0 ? null : valoracion.idServicio,
			ruta: valoracion.ruta,
			adicional: valoracion.adicional,
			tipoIVA: valoracion.tipoIVA,
		};

		return objetoValoracion;
	});

	return devolver;
};

export const getNombreFicheroEtiquetaEnvio = (datosEnvio = {}) => {
	let nombreFichero = `etiqueta_envio_${moment().format("YYYY-MM-DD_HH:mm")}.pdf`;

	if (!isEmpty(datosEnvio)) {
		nombreFichero = `etiqueta_envio_${datosEnvio.codExpedicion || datosEnvio.id}.pdf`;
	}

	return nombreFichero;
};

export const obtenerDatosValorarEnvios = async (listadoEnvios) => {
	let devolver = [];

	let var_envios = [];

	listadoEnvios &&
		listadoEnvios.forEach((item, indice) => {
			var_envios.push({
				indice: indice,
				idCliente: item.idCliente,
				idEnvio: item.id,
				idRed: item.idRed,
				idPaisOrigen: item.direccionRecogida.idPais,
				cp_origen: item.direccionRecogida.cp,
				idPaisDestino: item.direccion.idPais,
				cp_destino: item.direccion.cp,
			});
		});

	//Pedimos los datos al simulador
	try {
		var respuesta = await getDatosEnvios({ notransmitir: 1 }, var_envios);

		devolver = JSON.parse(respuesta);
	} catch (error) {}

	return devolver;
};

export const getServiciosSelectorEnvios = (datosRedSeleccionada, portes, esRolAdministrativo = false) => {
	let serviciosSelect = [];

	let serviciosRed = datosRedSeleccionada.servicios_tarifa_asociada;

	if (portes === "debidos" && datosRedSeleccionada.servicios_tarifa_debidos.length > 0) {
		serviciosRed = datosRedSeleccionada.servicios_tarifa_debidos;
	}

	//Este array contiene más información sobre el servicio y el servicio en la tarifa.
	let datosTodosServicios = [
		...(datosRedSeleccionada.tarifa_asociada.tarifas_servicios || []),
		...(datosRedSeleccionada.tarifa_debidos.tarifas_servicios || []),
	];

	//servicios desactivados, si rol !=interno
	serviciosRed.forEach((item) => {
		let serv = false;

		serv = datosTodosServicios.find((i) => i.idServicio === item.id);

		if (serv) {
			var atributos = {
				disabled: false,
				codServicio: serv.servicio.codigoDeServicioEnLaRed,
			};

			/*
                Esto debería comprobar solo el "interno" de la tarifa, ya que debería ser el que manda,
                pero parece que Jose lo quiere así.
            */
			if ((serv.interno === "1" || serv.servicio.interno === "1") && !esRolAdministrativo) {
				atributos.disabled = true;
			}

			serviciosSelect.push({ ...item, ...atributos });
		}
	});

	return serviciosSelect;
};

export const revisar_aviso_kilometraje = (datosEnviar) => {
	let devolver = false;

	if (datosEnviar.recogidaFueraPlaza === "1" && datosEnviar.fechaRecogida) {
		let fechaActual = startOfDay(new Date());

		let fechaRecogida = startOfDay(parse(datosEnviar.fechaRecogida, "dd/MM/yyyy", new Date()));

		devolver = isEqual(fechaActual, fechaRecogida);
	}

	return devolver;
};

export const calculaComisionMensajero = ({
	datosTarifa,
	valoraciones,
	comisionMensajero,
	datosForm,
	redes,
	impuestos,
	variablesCampos,
	calculaValoraciones,
}) => {
	let datos = {
		comisionPorcentual: calcularComisionDesdePorcentaje(valoraciones.precio_total, comisionMensajero),
		comisionTarifa: 0,
		nombreTarifa: "",
		idTarifa: null,
	};

	if (!isEmpty(datosTarifa) && datosTarifa.idRed === datosForm.idRed) {
		//Enviamos las variables del envío en vez de las de la tarifa ya que solo se pueden usar las variables que se usen en la tarifa del envío.
		//Luego internamente, hay una posición que se rellena con los datos de la tarifa que toque, en variables.variablesRed.tarifa.
		const { valoraciones_new } = calculaValoraciones(
			[],
			datosForm.referencias,
			redes,
			datosForm.idRed,
			datosForm.idServicio,
			datosForm.idRuta,
			datosForm.portes,
			{ ...datosForm.variables, variablesCampos: variablesCampos },
			datosForm.referenciasVariables,
			0,
			datosTarifa,
		);

		let valoracionTarifaMensajero = calculaTotalValoraciones(valoraciones_new, impuestos);

		datos.comisionTarifa = valoracionTarifaMensajero.precio_total;

		datos.nombreTarifa = datosTarifa.nombre;

		datos.idTarifa = datosTarifa.id;
	}

	return datos;
};

export const seleccionarRed = (redesSelector, redActual, configRedDireccion = null, modoEdicion = true) => {
	//Si está en modo edición (La primera vez que se accede a la página), cogemos el valor guardado en DB
	if (modoEdicion) {
		return redActual;
	}

	//Seleccionar la red automáticamente.
	if (redesSelector.length > 0) {
		//Miramos si hay configRed

		if (configRedDireccion) {
			//Revisamos si existe en el selector de redes
			let redEncontrada = redesSelector.find((red) => red.idRed === configRedDireccion.idRed);

			if (redEncontrada) {
				return redEncontrada.idRed;
			}
		}

		//Si ya hay una red seteada, y dicha red es válida con los nuevos datos
		if (redActual) {
			var redEncontrada = redesSelector.find((red) => red.idRed === redActual);

			if (redEncontrada) {
				return redActual;
			}
		}

		//Si no hay configRed, se coge la defecto o la primera.
		let redDefecto = redesSelector.find((red) => red.defecto === "1");

		if (redDefecto) {
			return redDefecto.idRed;
		}

		//Si no hay red defecto, se coge la primera.
		return redesSelector[0].idRed;
	}

	return "";
};

export const seleccionarPortes = (redSeleccionada, redActual, portesActual, modoEdicion) => {
	var portesSeleccionar = portesActual || "pagados";

	if (!modoEdicion) {
		if (redSeleccionada.portes && redActual !== redSeleccionada.idRed) {
			portesSeleccionar = redSeleccionada.portes;
		}
	}

	return portesSeleccionar;
};

export const seleccionaServicio = (
	serviciosSelect,
	servicioActual,
	datosRedSeleccionada,
	configRedDireccion = null,
	modoEdicion = true,
) => {
	if (modoEdicion) {
		return servicioActual;
	}

	//Si hay un servicio previamente seteado, revisamos si está en los nuevos servicios que aparecen.
	if (servicioActual) {
		let encontrado = serviciosSelect.some((servicio) => servicio.id === servicioActual && !servicio.disabled);

		if (encontrado) {
			return servicioActual;
		}
	}

	//Si hay una configRed y tiene servicio, establecemos ese si está en el selector
	if (configRedDireccion && configRedDireccion.idServicio) {
		let encontrado = serviciosSelect.some((servicio) => servicio.id === configRedDireccion.idServicio && !servicio.disabled);

		if (encontrado) {
			return configRedDireccion.idServicio;
		}
	}

	//Si el servicio no ha sido encontrado y hay configurado un servicio por defecto, tratamos de poner ese
	if (datosRedSeleccionada.clienteRed.idServicio) {
		//Si hay un servicio por defecto configurado en la red, comprobamos si está en el selector.

		var existe = serviciosSelect.some((servicio) => servicio.id === datosRedSeleccionada.clienteRed.idServicio && !servicio.disabled);

		if (existe) {
			return datosRedSeleccionada.clienteRed.idServicio;
		}
	}

	//Si no se ha encontrado nada, devolvemos el primer servicio no disabled.

	return serviciosSelect.find((servicio) => !servicio.disabled)?.id || "";
};

export const dameVariablesTarifa = (redes, idRed, portes) => {

	const red = redes.find((i) => i.red.id === idRed);

	let tarifa = red?.tarifa_asociada;

	if (portes === "debidos" && red?.tarifa_debidos?.id) {
		tarifa = red?.tarifa_debidos;
	}

	return (tarifa?.variables ?? []).filter((i) => i.activa === "1");
};

export const dameVariablesRedDisponiblesFiltradas = (variablesDisponibles, idServicio, idRuta, tarifaVariables) => {
	let devolver = [];

	if (variablesDisponibles.length > 0) {
		let variablesFiltrar = filtraServiciosVinculados(variablesDisponibles, idServicio, idRuta);

		if (variablesFiltrar.length > 0 && tarifaVariables.length > 0) {
			//Filtramos con las que vienen en tarifas

			variablesFiltrar.forEach((item) => {
				let tarifaVar = tarifaVariables.find((i) => i.idVariable === item.id);

				if (tarifaVar) {
					devolver.push({ ...item, tarifa: tarifaVar });
				}
			});
		}
	}

	return devolver;
};

export const dameVariablesEstablecerConfigDireccion = (serviciosSelect, idServicioSeleccionar, variablesRed, redesDisponibles, idRed, portes, variablesActuales, configRedDireccion) => {
	
	let variables = cloneDeep(variablesActuales);

	const datosServicioSeleccionado = serviciosSelect.find(servicio => servicio.id === idServicioSeleccionar);

	if (variablesRed?.length > 0 && datosServicioSeleccionado)
	{


		const variablesDisponiblesTarifa = dameVariablesTarifa(redesDisponibles, idRed, portes);

		const variablesDisponiblesFiltradas = dameVariablesRedDisponiblesFiltradas(variablesRed, idServicioSeleccionar, datosServicioSeleccionado.idRuta, variablesDisponiblesTarifa);

		configRedDireccion.variables.forEach(variable => {

			const variableDisponible = variablesDisponiblesFiltradas.find(v => v.id === variable.idVariable && (v.opcional === "1" || v.depende === "variable"));

			if (variableDisponible)
			{
				variables.variablesOpcionales.push({
					idVariable: variableDisponible.id,
					valor: variableDisponible.depende === "variable" ? variable.valor : null
				});
			}


		})
	}

	return variables;
}
