import { useQueryClient } from "@tanstack/react-query";
import { useApiGetConfig } from "app/hooks/api/config/useApiGetConfig";
import { swalCondicional, swalError } from "app/librerias/swal";
import { sacarAvisoHoraMaximaSobrepasada, sacarAvisoRecogidaEnFechaAnterior } from "app/modules/recogidas/helpers/recogidas.helper";
import { last } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { useDelta } from "react-delta";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import TienePermisos from "../helpers/TienePermisos";
import { base64ToBlob, ejecutaAPI, formatTimeToHHMM, imprimeUrl, prepararDatosSchema, stringAFormatoHis } from "../helpers/libFunciones";
import { recogidas, usuarios } from "../helpers/permisos";
import { SelectMensajeros } from "../helpers/selects";
import { importacionEnvios, validador } from "../pages/home/recogidas/schemas";
import { useGlobalLoader, useRoles } from "./hooksPropios";

export const RecogidaContext = React.createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case "CARGANDO_INIT":
			return { ...state, cargando: true };
		case "CARGANDO_FIN":
			return { ...state, cargando: false };
		case "SET_CLIENTES":
			return { ...state, clientes: action.payload };
		case "SET_DEPARTAMENTOS_CLIENTES":
			return { ...state, departamentosClientes: action.payload };
		case "SET_RECOGIDA":
			return { ...state, datosRecogida: action.payload, error: { ...state.error, error: false, msg: "" } };
		case "SET_VALORACIONES":
			return { ...state, datosRecogida: { ...state.datosRecogida, ...action.payload } };
		case "SET_PAGOS":
			return { ...state, datosRecogida: { ...state.datosRecogida, pagos: action.payload } };
		case "SET_MENSAJEROS":
			return { ...state, mensajeros: action.payload };
		case "SET_GENERAR_ENVIO":
			return { ...state, generarEnvio: action.payload };
		case "SET_CLIENTE":
			return { ...state, fichaCliente: action.payload };
		case "SET_DEPARTAMENTOS":
			var departamentosClientes = [...state.departamentosClientes, ...action.payload.departamentos];

			var departamentosActual = departamentosClientes.filter((dpto) => dpto.idCliente === action.payload.idCliente);

			let direccionesCliente = [];

			//Rellena "direccionesCliente" con las direcciones habituales de los departamentos del cliente.

			departamentosActual.forEach((dpto) => {
				let direccionesHabituales = dpto.direcciones.filter((dir) => dir.habitual === "1");

				direccionesCliente = [...direccionesCliente, ...direccionesHabituales];
			});

			return {
				...state,
				departamentosClientes: departamentosClientes,
				departamentos: departamentosActual,
				direcciones: direccionesCliente,
			};

		case "SET_ERROR":
			return { ...state, cargando: false, error: { ...state.error, error: action.payload.error, msg: action.payload.msg } };
		case "SET_ENVIOS_RECOGIDA":
			return { ...state, enviosRecogida: action.payload };
		case "SET_ENVIOS_RECOGIDA_ANULAR":
			return { ...state, enviosRecogida: action.payload, enviosSeleccionados: [] };
		case "SELECCIONA_ENVIOS":
			return { ...state, enviosSeleccionados: action.payload };
		default:
			throw new Error();
	}
};

export const RecogidaProvider = ({ children }) => {
	const [recogida, dispatch] = useReducer(reducer, {
		datosRecogida: {},
		cargando: true,
		generarEnvio: false,
		clientes: [],
		mensajeros: [],
		departamentos: [],
		departamentosClientes: [],
		fichaCliente: {},
		enviosRecogida: [],
		direcciones: [],
		enviosSeleccionados: [],
		error: {
			error: false,
			msg: "",
		},
	});

	return <RecogidaContext.Provider value={[recogida, dispatch]}>{children}</RecogidaContext.Provider>;
};

export const useRecogida = (idRecogida) => {
	const [recogida, dispatch] = useContext(RecogidaContext);

	const location = useLocation();

	const history = useHistory();

	const { setGlobalCargando } = useGlobalLoader();

	const recogidaRef = useDelta(idRecogida);

	const clientesRedux = useSelector((state) => state.clientes);

	const { data: config, isInitialLoading: cargandoConfig } = useApiGetConfig();

	const dispatchRedux = useDispatch();

	const { esCliente } = useRoles();

	useEffect(() => {
		if (recogidaRef !== null) {
			dispatch({ type: "CARGANDO_INIT" });

			(async () => {
				//Traer datos de los clientes

				var listadoClientes = [];

				//1h cache clientes
				if (
					clientesRedux &&
					clientesRedux?.clientesTrafico_time &&
					Date.now() / 1000 - clientesRedux?.clientesTrafico_time / 1000 < 3600
				) {
					dispatch({ type: "SET_CLIENTES", payload: clientesRedux.clientesTrafico });
					listadoClientes = clientesRedux.clientesTrafico;
				} else {
					const { success, respuesta } = await ejecutaAPI("GET", `clientes?per_page=-1&simplificado=1&activo=1`);

					if (success) {
						listadoClientes = respuesta.data;
						dispatch({ type: "SET_CLIENTES", payload: listadoClientes });
						dispatchRedux({ type: "SET_CLIENTES_TRAFICO", payload: listadoClientes });
					} else {
						dispatch({ type: "SET_CLIENTES", payload: [] });
						dispatch({ type: "SET_DEPARTAMENTOS_CLIENTES", payload: [] });
					}
				}

				var arrayMensajeros = [];

				if (TienePermisos([], [usuarios.ver, recogidas.ver_todos]) || esCliente) {
					let usuarios = await SelectMensajeros();

					if (usuarios) {
						arrayMensajeros = usuarios.map((mensajero) => {
							return { id: mensajero.id, value: `${mensajero.nombre} ${mensajero.apellidos}` };
						});

						dispatch({ type: "SET_MENSAJEROS", payload: arrayMensajeros });
					}
				}

				//Traer datos de la recogida

				if (!isNaN(idRecogida) && idRecogida > 0) {
					var datosRecogida = await ejecutaAPI("GET", `recogidas/${idRecogida}`);

					if (datosRecogida.success) {
						datosRecogida = datosRecogida.respuesta;
						//Preparación de campos.
						datosRecogida.direccion.habitual = datosRecogida.direccion.habitual === "1" ? true : false;

						dispatch({ type: "SET_RECOGIDA", payload: datosRecogida });
					} else {
						dispatch({ type: "SET_ERROR", payload: { error: true, msg: "No se ha encontrado la recogida" } });
					}
				} else {
					var camposIniciales = validador.cast();

					camposIniciales.fecha = moment().format("DD/MM/YYYY");

					camposIniciales.bultos = "1";

					camposIniciales.envios = "1";

					if (location.state !== undefined) {
						camposIniciales.idCliente = location.state.idCliente;

						camposIniciales.idDepartamento = location.state.idDepartamento;

						//Revisar los datos por defecto del cliente

						if (listadoClientes.length > 0) {
							var clienteSeleccionado = listadoClientes.filter((cliente) => cliente.id === location.state.idCliente);

							if (clienteSeleccionado.length > 0) {
								clienteSeleccionado = clienteSeleccionado[0];

								var datosDepartamento = clienteSeleccionado?.departamentos.filter(
									(departamento) => departamento.id === location.state.idDepartamento,
								);

								if (datosDepartamento.length > 0) {
									datosDepartamento = datosDepartamento[0];
								}

								camposIniciales.horaDesde = datosDepartamento.recogerDesdeDefecto
									? datosDepartamento.recogerDesdeDefecto
									: clienteSeleccionado.recogerDesdeDefecto
									? clienteSeleccionado.recogerDesdeDefecto
									: moment().format("HH:mm");

								camposIniciales.horaHasta = datosDepartamento.recogerHastaDefecto
									? datosDepartamento.recogerHastaDefecto
									: clienteSeleccionado.recogerHastaDefecto
									? clienteSeleccionado.recogerHastaDefecto
									: moment().format("HH:mm");

								camposIniciales.observacionesRecogida =
									datosDepartamento.observaciones != ""
										? datosDepartamento.observaciones
										: clienteSeleccionado.observaciones != ""
										? clienteSeleccionado.observaciones
										: "";

								if (
									(clienteSeleccionado.idMensajeroDefecto || datosDepartamento.idMensajeroDefecto) &&
									arrayMensajeros.length > 0
								) {
									var existeCliente = arrayMensajeros.some(
										(mensajero) => mensajero.id === clienteSeleccionado.idMensajeroDefecto,
									);
									var existeDepartamento = arrayMensajeros.some(
										(mensajero) => mensajero.id === datosDepartamento.idMensajeroDefecto,
									);

									if (existeDepartamento) {
										camposIniciales.idMensajero = datosDepartamento.idMensajeroDefecto;
									} else if (existeCliente) {
										camposIniciales.idMensajero = clienteSeleccionado.idMensajeroDefecto;
									}
								}
							}
						}
					}

					dispatch({ type: "SET_RECOGIDA", payload: camposIniciales });
				}

				dispatch({ type: "CARGANDO_FIN" });
			})();
		}
	}, [idRecogida, recogidaRef, dispatch, dispatchRedux, clientesRedux, location.state, setGlobalCargando, esCliente]);

	//SETEAR VALORES INICIALES
	useEffect(() => {
		if (location.state !== undefined) {

			if (location.state?.idsEnviosImprimir?.length > 0) {
				(async () => {
					const { success, respuesta } = await ejecutaAPI("POST", `envios/etiquetas`, location.state.idsEnviosImprimir);
	
					if (success) {
						if (respuesta.PDFEtiquetasBase64) {
							var blob = base64ToBlob(respuesta.PDFEtiquetasBase64, "application/pdf");
	
							var blobUrl = URL.createObjectURL(blob);

							imprimeUrl(blobUrl);
						}
					}
				})();
			}
		}
	}, [history, location.state, dispatch]);


	return {
		...recogida,
		config,
		cargando: recogida.cargando || cargandoConfig,
		dispatch,
	};
};

export const useRecogidaContext = () => {
	const [recogida, dispatch] = useContext(RecogidaContext);

	const history = useHistory();

	const { esRolAdministrativo, esCliente, esMensajero } = useRoles();

	const { setGlobalCargando } = useGlobalLoader();

	const queryClient = useQueryClient();

	const config = queryClient.getQueryData(["config"]);

	const borrarRecogida = (id) => {
		Swal.fire({
			title: "¿Estás seguro de eliminarla?",
			text: "",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				setGlobalCargando(true);

				var respuesta = await ejecutaAPI("DELETE", `recogidas/${id}`);

				if (respuesta.success) {
					Swal.fire({
						title: "Exito",
						text: "Se ha borrado correctamente",
						icon: "success",
						showConfirmButton: true,
					}).then((result) => {
						history.push("/");
					});
				} else {
					Swal.fire({
						title: "Error",
						text: "No se ha podido borrar la recogida.",
						icon: "error",
					});
				}

				setGlobalCargando(false);
			}
		});
	};

	const handleSubmit = useCallback(
		async (values) => {
			var schema = validador.cast();

			if (values.direccionDestino) {
				schema.direccionDestino = { ...schema.direccion };
			}

			const sacarAvisoHoras = sacarAvisoHoraMaximaSobrepasada(values.fecha, config?.horaMaximaRecogidas);

			const sacarAvisoFechaAnterior = sacarAvisoRecogidaEnFechaAnterior(values.fecha);
			/*
			 *	COMPROBACIONES:
			 *	1- Si la hora máxima de recogida, para una recogida de hoy, es menor que la hora actual, se mostrará un aviso. La recogida se generará por defecto para el día siguiente laborable.
			 *	2- Si la fecha de la recogida es anterior a la fecha actual, y se va a generar un envío, se mostrará un aviso. El envío se guardará para la fecha que esté puesta en la recogida.
			 */

			swalCondicional(
				{
					title: "ATENCIÓN",
					text: `La hora máxima de recogida es ${formatTimeToHHMM(
						config?.horaMaximaRecogidas,
					)}. Si continúa, la recogida se genererá por defecto para el siguiente día laborable. ¿Desea continuar?`,
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Continuar",
					cancelButtonText: "Cancelar",
				},
				!values.id && esCliente && sacarAvisoHoras,
			).then(async (continuar) => {
				if (continuar) {
					swalCondicional(
						{
							title: "ATENCIÓN",
							text: `La recogida tiene una fecha (${values.fecha}) anterior al día actual , si continúa, el envío que genere se guardará por defecto para el día ${values.fecha}. ¿Desea continuar?`,
							icon: "warning",
							showCancelButton: true,
							confirmButtonText: "Continuar",
							cancelButtonText: "Cancelar",
						},
						recogida.generarEnvio && sacarAvisoFechaAnterior,
					).then(async (continuar) => {
						if (continuar) {
							setGlobalCargando(true);

							var datosEnviar = prepararDatosSchema(values, schema);

							var verbo = "POST";

							var url = `recogidas`;

							if (datosEnviar.id !== undefined && datosEnviar.id !== "" && datosEnviar.id > 0) {
								verbo = "PUT";

								url = `recogidas/${datosEnviar.id}`;
							}

							datosEnviar.horaDesde = stringAFormatoHis(datosEnviar.horaDesde);

							datosEnviar.horaHasta = stringAFormatoHis(datosEnviar.horaHasta);

							const { success, respuesta } = await ejecutaAPI(verbo, url, datosEnviar);

							if (success) {
								if (recogida.generarEnvio) {
									history.push(`/envios/new`, { idRecogida: respuesta.id });
								} else {
									respuesta.direccion.habitual = respuesta.direccion.habitual === "1" ? true : false;

									dispatch({ type: "SET_RECOGIDA", payload: respuesta });

									var urlRedireccion = `/recogidas/${respuesta.id}`;

									if (esCliente) {
										urlRedireccion = `/cliente/trafico`;
									}

									history.push(urlRedireccion);
								}
							} else {
								Swal.fire({
									title: "Error",
									text: "Se ha producido un error guardando la recogida.",
									icon: "error",
								});
							}

							setGlobalCargando(false);
						}
					});
				}
			});
		},
		[config, dispatch, esCliente, history, recogida?.generarEnvio, setGlobalCargando],
	);

	const traspasarEnvios = (values) => {
		if (recogida.enviosSeleccionados.length > 0) {
			Swal.fire({
				title: `¿Estás seguro de traspasar ${recogida.enviosSeleccionados.length} envios?`,
				text: "",
				icon: "info",
				showCancelButton: true,
				confirmButtonText: "Aceptar",
				cancelButtonText: "Cancelar",
			}).then(async (result) => {
				if (result.value) {
					setGlobalCargando(true);

					let idEnvios = recogida.enviosSeleccionados.map((i) => i.id);

					const data = {
						idCliente: values.idCliente,
						idDepartamento: values.idDepartamento,
						envios: idEnvios,
					};

					var { success, respuesta } = await ejecutaAPI("PUT", `/recogidas/traspasar_envios`, data);

					if (success) {
						let mensajeMostrar = `Se han traspasado ${respuesta.envios_traspasados} envios `;

						if (respuesta.enviosNoProcesados.length > 0) {
							mensajeMostrar += `<br/><strong>No se han podido traspasar los siguientes envios:</strong>`;

							respuesta.enviosNoProcesados.forEach((envioNoTraspasado) => {
								mensajeMostrar += `<br/> - <strong>${envioNoTraspasado.id}</strong>: ${envioNoTraspasado.error}`;
							});
						}

						Swal.fire({
							title: "Exito",
							html: mensajeMostrar,
							icon: "success",
							showConfirmButton: true,
						}).then(() => {
							let recogidaCreada = respuesta.recogidasCreadas[0]?.idRecogida;

							if (recogidaCreada) {
								//Abrimos la nueva recogida
								const url = `/recogidas/${recogidaCreada}/envios`;
								window.open(url);

								//Recargamos
								history.go(0);
							}
						});
					} else {
						let mensajeMostrar = respuesta?.data?.msg?.join(",") || "No se han podido traspasar los envios";

						if (respuesta?.data?.data?.enviosNoProcesados?.length > 0) {
							respuesta?.data?.data?.enviosNoProcesados.forEach((envioNoTraspasado) => {
								mensajeMostrar += `<br/> - <strong>${envioNoTraspasado.id}</strong>: ${envioNoTraspasado.error}`;
							});
						}

						swalError("Error", mensajeMostrar);
					}

					setGlobalCargando(false);
				}
			});
		}
	};

	const handleSubmitValoraciones = async (values) => {
		setGlobalCargando(true);

		var datosEnviar = {
			valoraciones: [],
			comisionManual: values.comisionManual,
			comisionMensajero: values.comisionMensajero,
			comisionComercial: values.comisionComercial,
			idComercial: values.idComercial,
		};

		if (values.valoraciones.length > 0) {
			datosEnviar.valoraciones = values.valoraciones.map((e) => ({
				referencia: e.ref,
				servicio: e.servicio,
				coste: e.coste,
				precio: e.precio,
				info: e.info,
				tipoIVA: e.tipoIVA,
			}));
		}

		var verbo = "PUT";

		var url = `recogidas/${values.id}`;

		const { success } = await ejecutaAPI(verbo, url, datosEnviar);

		if (success) {
			Swal.fire({
				title: "Exito",
				text: "Se ha realizado la acción correctamente",
				icon: "success",
				showConfirmButton: true,
			}).then(() => {
				dispatch({ type: "SET_VALORACIONES", payload: datosEnviar });
			});
		} else {
			Swal.fire({
				title: "Error",
				text: "Se ha producido un error guardando la recogida.",
				icon: "error",
			});
		}

		setGlobalCargando(false);
	};

	const handleSubmitDatosFicheros = async (values) => {
		var datosEnviar = values;

		var verbo = "PUT";

		var url = `recogidas/${values.id}`;
		var entra = false;
		var msg = "";

		if (esCliente && values.finalizadoDatosEnvioCliente == false) {
			msg = "Recuerda que debes finalizar los datos de envio para que la agencia los vea.";
		} else if (esRolAdministrativo && values.finalizadoDatosEnvio == false) {
			msg = "Recuerda que debes finalizar los datos de envio para que el cliente los vea.";
		}

		if (msg != "") {
			await Swal.fire({
				title: "Aviso",
				text: msg,
				icon: "info",
			}).then(() => {
				entra = true;
			});
		} else {
			entra = true;
		}

		if (entra) {
			setGlobalCargando(true);

			const { success, respuesta } = await ejecutaAPI(verbo, url, datosEnviar);

			if (success) {
				Swal.fire({
					title: "Exito",
					text: "Se ha realizado la acción correctamente",
					icon: "success",
				}).then(() => {
					if (respuesta?.enviarEmail) {
						if (respuesta.enviarEmail.msg != "") {
							Swal.fire({
								title: "Email",
								text: respuesta.enviarEmail.msg,
								icon: respuesta.enviarEmail.success ? "success" : "error",
							});
						}
					}

					dispatch({ type: "SET_RECOGIDA", payload: respuesta });
				});
			} else {
				Swal.fire({
					title: "Error",
					text: "Se ha producido un error guardando la recogida.",
					icon: "error",
				});
			}
		}

		setGlobalCargando(false);
	};

	const setGenerarEnvio = (valor) => {
		dispatch({ type: "SET_GENERAR_ENVIO", payload: valor });
	};

	const guardaImagenes = async (imagenes) => {
		setGlobalCargando(true);

		var datosEnviar = {
			imagenes: imagenes.filter((imagen) => imagen != undefined).map((imagen) => imagen.id),
		};

		const { success, respuesta } = await ejecutaAPI("PUT", `recogidas/${recogida.datosRecogida.id}`, datosEnviar);

		if (success) {
			Swal.fire({
				title: "Exito",
				text: "Se ha realizado la acción correctamente",
				icon: "success",
				showConfirmButton: true,
			}).then(() => {
				respuesta.direccion.habitual = respuesta.direccion.habitual === "1" ? true : false;

				dispatch({ type: "SET_RECOGIDA", payload: respuesta });
			});
		} else {
			Swal.fire({
				title: "Error",
				text: "Se ha producido un error guardando las imágenes.",
				icon: "error",
			});
		}

		setGlobalCargando(false);
	};

	const guardaFicheros = async (ficheros, cliente = 0) => {
		setGlobalCargando(true);

		var datosEnviar = {
			ficheros: ficheros.filter((fichero) => fichero != undefined).map((fichero) => fichero.id),
			cliente: cliente,
		};

		const { success, respuesta } = await ejecutaAPI("PUT", `recogidas/${recogida.datosRecogida.id}`, datosEnviar);

		if (success) {
			Swal.fire({
				title: "Exito",
				text: "Se ha realizado la acción correctamente",
				icon: "success",
				showConfirmButton: true,
			}).then(() => {
				respuesta.direccion.habitual = respuesta.direccion.habitual === "1" ? true : false;

				dispatch({ type: "SET_RECOGIDA", payload: respuesta });
			});
		} else {
			Swal.fire({
				title: "Error",
				text: "Se ha producido un error guardando los ficheros.",
				icon: "error",
			});
		}

		setGlobalCargando(false);
	};

	const imprimeEnvio = async (id) => {
		//Obtener los datos del envío.
		setGlobalCargando(true);

		const { success, respuesta } = await ejecutaAPI("GET", `envios/${id}/opciones/imprimir`);

		if (success) {
			if (respuesta.PDFEtiquetasBase64) {
				var blob = base64ToBlob(respuesta.PDFEtiquetasBase64, "application/pdf");

				var blobUrl = URL.createObjectURL(blob);

				imprimeUrl(blobUrl);

				var envios_new = recogida.enviosRecogida.map((item) => {
					if (item.id == id) {
						return { ...item, fechaUltImpresion: moment().format("DD/MM/YYYY HH:mm:ss") };
					} else {
						return item;
					}
				});

				dispatch({ type: "SET_ENVIOS_RECOGIDA", payload: envios_new });
			} else {
				Swal.fire({
					title: "Aviso",
					text: "El envío no tiene etiquetas generadas",
					icon: "info",
				});
			}
		} else {
			Swal.fire({
				title: "Error",
				text: "Se ha producido un error obteniendo los datos del envío",
				icon: "error",
			});
		}

		setGlobalCargando(false);
	};

	const esEditable = () => {
		var devolver = true;

		var infoEstado = last(recogida.datosRecogida.estados);

		if (esCliente || esMensajero) {
			if (recogida.datosRecogida.facturado) {
				devolver = false;
			}

			if (infoEstado) {
				if (infoEstado.codigo === "recogido" || infoEstado.codigo === "terminado") {
					devolver = false;
				}
			}
		}

		return devolver;
	};

	const estaFacturado = () => {
		if (recogida.datosRecogida.facturado || recogida.datosRecogida.facturadoEnvios) {
			return true;
		} else {
			return false;
		}
	};

	const getEnviosRecogida = useCallback(
		async (recargar = false) => {
			if (recogida.enviosRecogida.length === 0 || recargar) {
				const { success, respuesta } = await ejecutaAPI(
					"GET",
					`envios?idRecogida=${recogida.datosRecogida.id}&anulado=0&barcode=1&per_page=-1`,
				);

				if (success) {
					dispatch({ type: "SET_ENVIOS_RECOGIDA", payload: respuesta.data });
				}
			}
		},
		[dispatch, recogida.datosRecogida.id, recogida.enviosRecogida],
	);

	const imprimirEtiquetasRecogida = async (formato) => {
		setGlobalCargando(true);

		const envios =
			recogida.enviosSeleccionados.length > 0
				? recogida.enviosSeleccionados.map((envio) => envio.id)
				: recogida.enviosRecogida.map((envio) => envio.id);

		const data = { envios: envios, formato: formato };

		const { success, respuesta } = await ejecutaAPI("POST", `envios/etiquetas`, data);

		if (success) {
			if (respuesta.PDFEtiquetasBase64) {
				var blob = base64ToBlob(respuesta.PDFEtiquetasBase64, "application/pdf");

				var blobUrl = URL.createObjectURL(blob);

				imprimeUrl(blobUrl);

				var envios_new = recogida.enviosRecogida.map((item) => {
					if (envios.indexOf(item.id) >= 0) {
						return { ...item, fechaUltImpresion: moment().format("DD/MM/YYYY HH:mm:ss") };
					} else {
						return item;
					}
				});

				dispatch({ type: "SET_ENVIOS_RECOGIDA", payload: envios_new });
			}
		} else {
			var error = respuesta.data?.msg?.join("<br>");

			if (!error) {
				error = "Se ha producido un error obteniendo las etiquetas";
			}

			Swal.fire({
				title: "Error",
				text: error,
				icon: "error",
			});
		}

		setGlobalCargando(false);
	};

	return {
		...recogida,
		borrarRecogida,
		handleSubmit,
		guardaImagenes,
		guardaFicheros,
		setGenerarEnvio,
		imprimeEnvio,
		esEditable,
		imprimirEtiquetasRecogida,
		getEnviosRecogida,
		validador,
		importacionEnvios,
		dispatch,
		handleSubmitValoraciones,
		handleSubmitDatosFicheros,
		estaFacturado,
		traspasarEnvios,
		configData: config,
	};
};
